<template src="./DeleteConfirmModal.html"></template>
<style scoped lang="scss" src="./DeleteConfirmModal.scss"></style>

<script>
export default {
  name: 'DeleteConfirmModal',
  data: function () {
    return {
      isShow: false
    }
  },
  props: {
    isLoading: Boolean,
    isShowDialog: Boolean,
    title: String,
    message: String,
    subMessage: String,
  },
  watch: {
    isShowDialog: function (show) {
      this.isShow = show
    }
  },
  methods: {
    onClose() {
      this.isShow = false
      this.$emit('close')
    },

    clickToCancelDeleteButton() {
      this.isShow = false
      this.$emit('cancel')
    },

    clickToConfirmDeleteButton() {
      this.isShow = false
      this.$emit('submit')
    }
  }
}
</script>
